import {Main} from "./Main";
import './styles.scss';
import {About} from "./About";
import {Services} from "./Services";
import {Offer} from "./Offer";
import {Contact} from "./Contact";

export function MainContainer(props) {

    return (
        (() => {
            switch(props.tab) {
                case 'main':
                    return <Main />
                case 'about':
                    return <About />
                case 'services':
                    return <Services />
                case 'offer':
                    return <Offer />
                case 'contact':
                    return <Contact />
                default:
                    return <Main/>
            }
        })()
    )
}
