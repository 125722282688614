import './App.css';
import './styles.scss';

import {Routes, Route, BrowserRouter as Router, Link} from "react-router-dom";
import {MainContainer} from "./subsites/MainContainer";
import {useState} from "react";

function App() {
    const [showMenu, setShowMenu] = useState(false);
    return (
        <div className="root">
            <div className='top-line'>
            </div>
            <header className='header'>
                <h1>Kancelaria Radcy Prawnego</h1>
                <h1>kompleksowa pomoc prawna</h1>
            </header>
            <nav>
                <ul className={!showMenu && 'hide-menu'}>
                    <li><Link to='/' onClick={() => setShowMenu(false)}>strona główna</Link></li>
                    <li><Link to='/o-mnie' onClick={() => setShowMenu(false)}>o mnie</Link></li>
                    <li><Link to='/zakres-uslug' onClick={() => setShowMenu(false)}>zakres usług</Link></li>
                    <li><Link to='/wynagrodzenie' onClick={() => setShowMenu(false)}>wynagrodzenie</Link></li>
                    <li><Link to='/kontakt' onClick={() => setShowMenu(false)}>kontakt</Link></li>
                </ul>
                <button className='btn-mobile-nav'>
                    <div className={showMenu ? 'hide' : ''}
                         onClick={() => {setShowMenu(!showMenu)}}
                    ><ion-icon name="menu-outline"></ion-icon></div>
                    <div className={showMenu ? '' : 'hide'}
                         onClick={() => {setShowMenu(!showMenu)}}
                    ><ion-icon name="close-outline"></ion-icon></div>
                </button>
            </nav>
            <div className='main-grid'>
                <div className='dark-side-block left-margin'></div>
                <Routes>
                    <Route exact path='o-mnie' element={
                        <>
                            <div className='top-photo top-left-photo-about'></div>
                            <div className='top-photo top-mid-photo'></div>
                            <div className='top-photo top-right-photo-about'></div>
                        </>
                    }/>
                    <Route exact path='zakres-uslug' element={
                        <>
                            <div className='top-photo top-left-photo-zakres-uslug'></div>
                            <div className='top-photo top-mid-photo'></div>
                            <div className='top-photo top-right-photo-zakres-uslug'></div>
                        </>
                    }/>
                    <Route exact path='wynagrodzenie' element={
                        <>
                            <div className='top-photo top-left-photo-honorarium'></div>
                            <div className='top-photo top-mid-photo'></div>
                            <div className='top-photo top-right-photo-honorarium'></div>
                        </>
                    }/>
                    <Route exact path='kontakt' element={
                        <>
                            <div className='top-photo top-left-photo-kontakt'></div>
                            <div className='top-photo top-mid-photo-kontakt'></div>
                            <div className='top-photo top-right-photo-kontakt'></div>
                        </>
                    }/>
                    <Route exact path='*' element={
                        <>
                            <div className='top-photo top-left-photo-1'></div>
                            <div className='top-photo top-mid-photo'></div>
                            <div className='top-photo top-right-photo-1'></div>
                        </>
                    }/>
                </Routes>
                <div className='dark-side-block right-margin'></div>
                <div className='light-side-block left-margin'></div>
                <main>
                    <header className='page-header'>
                        <Routes>
                            <Route exact path='/' element={<h2>radca</h2>}/>
                            <Route exact path='/o-mnie' element={<h2 className={'about'}>o mnie</h2>}/>
                            <Route exact path='/zakres-uslug' element={<h2 className={'services'}>usługi</h2>}/>
                            <Route exact path='/wynagrodzenie' element={<h2 className={'offer'}>honorarium</h2>}/>
                            <Route exact path='/kontakt' element={<h2 className={'contact'}>kontakt</h2>}/>
                        </Routes>
                        <div>
                            <p>MAGDALENA PAWLAK</p>
                        </div>
                    </header>
                    <Routes>
                        <Route exact path='/' element={<MainContainer tab='main' />}/>
                        <Route exact path='/o-mnie' element={<MainContainer tab='about' />}/>
                        <Route exact path='/zakres-uslug' element={<MainContainer tab='services'/>}/>
                        <Route exact path='/wynagrodzenie' element={<MainContainer tab='offer' />}/>
                        <Route exact path='/kontakt' element={<MainContainer tab='contact' />}/>
                    </Routes>
                </main>
                <div className='light-side-block right-margin'></div>

                <div className='dark-side-block left-margin'></div>
                <div className='dark-side-block right-margin'></div>

                <div className='email-tile contact-tile'>
                <a className='tile-content' href="mailto: kancelaria@radcaprawnypawlak.pl">
                    <div className='email-icon icon'></div>
                    <div>kancelaria@radcaprawnypawlak.pl</div>
                </a>
            </div>
                <div className='address-tile contact-tile'>
                    <div className='tile-content'>
                        <div className='home-icon icon'></div>
                        <div>adres do doręczeń:<br/> ul. Księcia Witolda 38-40/2<br/> 50-202 Wrocław</div>
                    </div>
                </div>
                <div className='phone-number-tile contact-tile'>
                <a href="tel:531173467" className='tile-content'>
                    <div className='phone-icon icon'></div>
                    <div>+48 531 173 467</div>
                </a>
                </div>
            </div>
            <footer>
                <div>&copy; 2022 Kancelaria | Pawlak</div>
                <div>
                    <ul>
                        <li>Impressum</li>
                        <li>Ochrona danych</li>
                    </ul>
                </div>
            </footer>
        </div>
    );
}

export default App;
