import React from 'react';
import './Services.styles.scss';

export function Services() {
    return (
        <article className='main-content'>
            <h3 className='main-content-header'>
                <span>odszkodowania</span>
            </h3>
            <div className='main-content-text'>
                <p>Pomagam uzyskać odszkodowanie indywidualnym klientom. </p>
                <p>Formułuję roszczenia odszkodowawcze za szkody na mieniu (kolizje drogowe, uszkodzenia pojazdu) i&nbsp;na osobie. </p>
                <p>Reprezentuję pokrzywdzonych w&nbsp;sprawach o&nbsp;zadośćuczynienie za śmierć osoby najbliższej.</p>
            </div>
            <h3 className='main-content-header'>
                <span>prawo </span><span>rodzinne</span>
            </h3>
            <div className='main-content-text'>
                <p>Specjalizuję się w sprawach rodzinnych, a&nbsp;w&nbsp;szczególności w&nbsp;sprawach rozwodowych i&nbsp;alimentacyjnych oraz związanych z&nbsp;wykonywaniem władzy rodzicielskiej i&nbsp;kontaktów z&nbsp;dzieckiem.</p>
            </div>
            <h3 className='main-content-header'>
                <span>kredyty </span><span>we frankach</span>
            </h3>
            <div className='main-content-text'>
                <p>Kredyty frankowe to moja specjalność.</p>
                <p>Frankowicze i&nbsp;inni kredytobiorcy mający kredyt we frankach szwajcarskich, euro czy jenach japońskich powinni skorzystać z&nbsp;oferty Kancelarii w&nbsp;zakresie analizy umowy o&nbsp;kredyt indeksowany/denominowany w&nbsp;walucie obcej.</p>
                <p>Po darmowej i&nbsp;pomyślnej analizie umowy kredytu poprowadzę sprawę przeciwko bankowi o&nbsp;unieważnienie umowy kredytu i&nbsp;inne roszczenia.</p>
            </div>
            <h3 className='main-content-header'>
                <span>prawo </span><span>pracy</span>
            </h3>
            <div className='main-content-text'>
                <p>W ramach pomocy prawnej z&nbsp;zakresu prawa pracy i&nbsp;ubezpieczeń społecznych podejmuję się reprezentacji klientów w&nbsp;sprawach o&nbsp;zapłatę, o&nbsp;ustalenie stosunku pracy, o&nbsp;przywrócenie do pracy, o&nbsp;odszkodowanie, a&nbsp;także o&nbsp;odzyskanie niewypłaconego wynagrodzenia.</p>
                <p>Reprezentuję klientów w&nbsp;sporach przed Zakładem Ubezpieczeń Społecznych.</p>
            </div>
            <h3 className='main-content-header'>
                <span>prawo </span><span>karne</span>
            </h3>
            <div className='main-content-text'>
                <p>Podejmę się obrony klienta na etapie postępowania przygotowawczego przed Prokuratorem, Policją, jak i&nbsp;w&nbsp;postępowaniu karnym sądowym.</p>
                <p>Pomagam nie tylko podejrzanym o&nbsp;popełnienie czynu zabronionego, ale i&nbsp;pokrzywdzonym przestępstwem, którzy chcą dochodzić swoich praw.</p>
            </div>
            <h3 className='main-content-header'>
                <span>prawo </span><span>cywilne</span>
            </h3>
            <div className='main-content-text'>
                <p>Doradzam w&nbsp;zakresie umów (umowy o&nbsp;świadczenie usług, umowy o&nbsp;dzieło, umowy pożyczek, umowy najmu, umowy sprzedaży i&nbsp;inne) – sporządzam, rozwiązuję i&nbsp;opiniuję umowy oraz przeprowadzam analizy ryzyka prawnego w&nbsp;zakresie zawieranych umów.</p>
                <p>Reprezentuję klientów w&nbsp;sprawach o&nbsp;odszkodowanie i&nbsp;zapłatę.</p>
            </div>
            <h3 className='main-content-header'>
                <span>błędy </span><span>medyczne</span>
            </h3>
            <div className='main-content-text'>
                <p>Prowadzę sprawy o&nbsp;odszkodowanie za błędy medyczne, a&nbsp;w&nbsp;tym za błędy popełnione w&nbsp;medycynie estetycznej, stomatologii i&nbsp;w&nbsp;trakcie innych zabiegów przeprowadzonych niezgodne ze sztuką lekarską.</p>
            </div>
            <h3 className='main-content-header'>
                <span>prawo </span><span>spadkowe</span>
            </h3>
            <div className='main-content-text'>
                <p>Prowadzę sprawy o&nbsp;zachowek, stwierdzenie nabycia spadku, przyjęcie i&nbsp;odrzucenie spadku, a&nbsp;także o&nbsp;unieważnienie testamentu.</p>
            </div>
            <h3 className='main-content-header'>
                <span>roboty budowlane </span><span>i remonty</span>
            </h3>
            <div className='main-content-text'>
                <p>Sporządzam i&nbsp;opiniuję umowy handlowe takie jak umowy o&nbsp;dzieło i&nbsp;umowy o&nbsp;roboty budowlane.</p>
                <p>Prowadzę sprawy sądowe z&nbsp;zakresu robót wykończeniowych, montażowych i&nbsp;naprawczych. Pomagam w&nbsp;odzyskaniu odszkodowania za wadliwie wykonany remont mieszkania.</p>
            </div>
            <h3 className='main-content-header'>
                <span>prawo gospodarcze </span><span>i obsługa spółek</span>
            </h3>
            <div className='main-content-text'>
                <p>Obsługuję spółki prawa handlowego i&nbsp;inne podmioty gospodarcze. </p>
                <p>Oferuję kompleksową pomoc przy składaniu wniosków i&nbsp;formularzy do Krajowego Rejestru Sądowego – zgłaszanie zmian do rejestru (zmiany umowy spółki - składu wspólników, wysokości kapitału zakładowego, treści uprawnień korporacyjnych), przygotowywanie umów, uchwał i&nbsp;protokołów.</p>
            </div>
        </article>
    )
}
